<template>
  <div>
    <span
      v-for="(list, index) in breadCrumbsData"
      :key="index"
      class="open-link"
    >
      <button
        v-if="breadCrumbsData.length - 1"
        @click="resetChart(list)"
      >
        {{ list.date }}<span v-if="index < breadCrumbsData.length - 1"> / </span>
      </button>
    </span>
    <apexchart
      v-if="data && data.length"
      ref="apexchart"
      type="bar"
      :options="chartOptions"
      :series="series"
      :height="height"
      :width="width"
      @click="selectedBar"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import { mapGetters } from "vuex";
import moment from 'moment';

export default {
  name: 'BarChart',
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    height: {
      type: [String, Number],
      default: 400,
    },
    width: {
      type: [String, Number],
      default: 500,
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      breadCrumbs: [],
      originalSeries: [],
      maxTripCount: 0,
      series: [
        {
          name: 'Trip Count',
          data: [],
        },
      ],
      chartOptions: {
        ...this.options,
        chart: {
          type: 'bar',
          width: '100%',
          height: '100%',
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '30%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: 'Trip Count',
          },
          min: 0,
          max: this.maxTripCount,
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter(val) {
              return + val + ' trips';
            },
          },
        },
      },
      drillDown: false,
      selectedDay: null,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      breadCrumbsData: "dashboard/getTripStartDate",
    }),
  },
  watch: {
    data: {
      handler() {
        this.updateChartData();
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.breadCrumbs.push({
      date: 'Reset',
      start: (this.options.date) ? this.options.date[0] : null,
      end: (this.options.date) ? this.options.date[1] : null
    });
    this.$store.dispatch("dashboard/setTripStartDate", this.breadCrumbs)

  },
  mounted() {
    this.updateChartData();
  },
  methods: {
    xAxisValues(start, end) {
      const startDate = new Date(start)
      const endDate = new Date(end)
      let dateformat = "MMM/DD";
      if (startDate.getFullYear() !== endDate.getFullYear()) {
        dateformat = "YYYY/MMM/DD";
      } else if (startDate.getDay() == endDate.getDay()) {
        dateformat = "MMM/DD h:mm"
      }
      const initDate = moment(start).format(dateformat)
      const finalDate = moment(end).format(dateformat)
      return `${initDate}-${finalDate}`;
    },
    setDrillDownValue(start, end) {
      const startDate = new Date(start)
      const endDate = new Date(end)
      if (startDate.getDay() !== endDate.getDay() && (endDate.getDay() - startDate.getDay()) > 1) {
        return true;
      } else {
        return false;
      }
    },
    updateChartData() {
      if (this.data && this.data.length > 0) {
        this.series[0].data = this.data.map(item => item.tripCount);
        this.chartOptions.xaxis.categories = this.data.map(item => {
          return this.xAxisValues(item.period_start_date, item.period_end_date)
        });
        this.$nextTick(() => {
          this.$refs.apexchart.updateOptions(this.chartOptions);
        });
        this.data.forEach(item => {
          if (item.tripCount > this.maxTripCount) {
            this.maxTripCount = item.tripCount;
          }
        });
      }
    },
    selectedBar(event, chartContext, opts) {
      const selectedBarIndex = opts.dataPointIndex;
      if (this.data && selectedBarIndex >= 0 && selectedBarIndex < this.data.length) {
        const clickedBarData = this.data[selectedBarIndex];
        const startDate = clickedBarData.period_start_date.split(" ")[0];
        const endDate = clickedBarData.period_end_date.split(" ")[0];
        this.options.date = [startDate, endDate];
       
        const dataValue = this.setDrillDownValue(clickedBarData.period_start_date, clickedBarData.period_end_date)
        if (dataValue) {
          let value = this.xAxisValues(clickedBarData.period_start_date, clickedBarData.period_end_date);
          this.breadCrumbs.push({
            date: value.replace(/\//g, ''),
            start: startDate,
            end: endDate
          });
          this.$store.dispatch("dashboard/setTripStartDate", this.breadCrumbs)
          this.$store.dispatch("dashboard/vechicleTrips", this.options)
        } else {
          this.$store.dispatch("alerts/error", this.$t("No more drill down available"));
        }
      }
    },
    resetChart(list) {
      const index = this.breadCrumbs.findIndex(breadcrumb => breadcrumb.date === list.date);

      if (index !== -1) {
        this.breadCrumbs.splice(index + 1);
        this.options.date = [list.start, list.end];
        if (list.start == null && list.end == null) {
          this.options.dateType = '';
        } 
        this.$store.dispatch("dashboard/setTripStartDate", this.breadCrumbs);
        this.$store.dispatch("dashboard/vechicleTrips", this.options);
      }
    },
  },
};
</script>
